<template>
  <v-badge
    :content="BasketLength"
    :value="BasketLength"
    color="green"
    overlap
    class="cart-display"
  >
    <v-icon class="mx-2" @click="basketDialog"> mdi-cart-outline </v-icon>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CartDisplayComponent',
  computed: {
    ...mapGetters(['getBasket']),
    BasketLength() {
      return this.getBasket.length
    },
  },
  methods: {
    basketDialog() {
      this.$router.push({ name: 'Cart' }).catch(() => {})
    },
  },
}
</script>

<style scoped></style>
