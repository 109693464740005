import axios from 'axios'
import store from '@/store'
//import router from '@/router'
//import { create } from 'vue-modal-dialogs'
//import LoginDialogView from '@/views/LoginDialogView'

export const GET = 'GET'
export const POST = 'POST'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'
export const PUT = 'PUT'

export class ApiException extends Error {
  constructor(code, message, type) {
    super((code ? `${code} - ` : '') + message)
    this.type = type
  }
  toString() {
    return `[${this.type}]`
  }
}

/*export class ApiFileDownloadException extends ApiException {
  constructor(fileName, error) {
    const msg = `Ошибка получения файла "${fileName}": ${error}`
    super(100, msg)
    this.name = 'ApiFileDownloadException'
    this.message = msg
    this.error = error
  }
  toString() {
    return `${this.message}`
  }
}*/

//let loginDlg = create(LoginDialogView, 'closeble', 'show-cancel')

export default class Api {
  static development = process.env.NODE_ENV !== 'production'
  static axiosConfig = {
    baseURL: this.development ? 'http://localhost:3044' : '/api/',
    headers: {
      'Content-type': 'application/json',
    },
  }
  static axios = null
  static token = () => {
    return store.getters.getToken
  }

  static initAxios() {
    const token = this.token()
    if (token) this.axiosConfig.headers['Authorization'] = `Bearer ${token}`
    return (this.axios = axios.create(this.axiosConfig))
  }

  static async doAxios(uri = '', data, method) {
    try {
      let response = null
      this.initAxios()
      if (method === GET || !method) response = await this.axios.get(uri)
      if (method === POST || !method)
        response = await this.axios.post(uri, data)

      if (response.data.answer !== 'ok') {
        throw new Error(`[${response.data.type}]: ${response.data.message}`)
      }

      return response.data
    } catch (error) {
      const code = error.response?.status || null
      switch (code) {
        /*case 401:
          if (router.currentRoute.name !== 'Login') {
            await loginDlg(true, false)
            return await this.doAxios(uri, data, method)
          }
          break*/
        case 400:
          throw error.response?.data?.message || 'Как-то так...'
        case 404:
          throw 'Не найдено'
        default:
          throw error
      }
    }
  }

  /*static async getFile(url, fileName = 'без_имени') {
    try {
      const { data, headers } = await this.axios.get(url, {
        responseType: 'blob',
      })
      const blob = new Blob([data], {
        type: headers['content-type'],
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (error) {
      throw new ApiFileDownloadException(fileName, error)
    }
  }*/

  static async get(url = null) {
    const { data } = await this.doAxios(url, null, GET)
    return data
  }

  static async post(url = null, dt = {}) {
    const { data } = await this.doAxios(url, dt, POST)
    return data
  }
}
