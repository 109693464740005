import Strings from '@/store/const/Strings'
import Vue from 'vue'

const state = {
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : Strings.defaultUser,
}

const getters = {
  getUser: state => {
    if (!state.user?.pVer) return Strings.defaultUser
    return state.user
  },
  getBasket: state => state.user.basket || [],
  getUserDelivery: state => state.user.delivery || null,
  getUserContacts: state => state.user.contacts || null,
  getUserPayment: state => state.user.payment || null,
  getUserAddress: state =>
    state.user.address || Object.assign({}, Strings.defaultUser.address),
}

const mutations = {
  SET_USER_CONTACTS: (state, value) => {
    state.user.contacts = { ...state.user.contacts, ...value }
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  SET_USER_ADDRESS: (state, value) => {
    state.user.address = { ...state.user.address, ...value }
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  SET_USER_PAYMENT: (state, value) => {
    state.user.payment = value
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  SET_USER_DELIVERY: (state, value) => {
    state.user.delivery = value
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  REMOVE_FROM_BASKET: async (state, item) => {
    const index = state.user.basket.findIndex(
      i => i.ProductID === item.ProductID
    )
    if (index > -1) {
      state.user.basket.splice(index, 1)
      localStorage.setItem('user', JSON.stringify(state.user))
    }
  },

  SET_QTY_BASKET: (state, { item, qt }) => {
    const index = state.user.basket.findIndex(
      i => i.ProductID === item.ProductID
    )
    if (index > -1) {
      Vue.set(state.user.basket, index, { ...state.user.basket[index], qt })
      localStorage.setItem('user', JSON.stringify(state.user))
    }
  },

  SUB_BASKET: (state, item) => {
    const index = state.user.basket.findIndex(
      i => i.ProductID === item.ProductID
    )
    if (index > -1) {
      const qt = state.user.basket[index].qt
      if (qt > 1) {
        const qt = state.user.basket[index].qt--
        Vue.set(state.user.basket, index, {
          ...state.user.basket[index],
          ...qt,
        })
      }
      if (qt === 1) {
        state.user.basket.splice(index, 1)
      }
    }
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  ADD_BASKET: (state, item) => {
    const index = state.user.basket.findIndex(
      i => i.ProductID === item.ProductID
    )
    const { ProductID, ProductName, ShortName, UnitPrice, Images } = item
    if (index === -1) {
      const qt = 1
      state.user.basket.push({
        ProductID,
        ProductName,
        ShortName,
        UnitPrice,
        Images,
        qt,
      })
    } else {
      const qt = state.user.basket[index].qt++
      Vue.set(state.user.basket, index, { ...state.user.basket[index], ...qt })
    }
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  SET_USER: (state, user) => {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
}

const actions = {
  // GET_PAYMENT: async ({ commit }) => {
  //   // TODO dict load from server
  //   const payment = [
  //     { id: 1, title: 'Наличными курьеру', icon: 'mdi-account-cash-outline' },
  //     {
  //       id: 2,
  //       title: 'Картой Сбербанка',
  //       icon: 'mdi-credit-card-outline',
  //     },
  //   ]
  //   commit('SET_PAYMENT', payment)
  // },
  //
  // GET_DELIVERY: async ({ commit }) => {
  //   // TODO dict load from server
  //   const delivery = [
  //     { id: 1, title: 'Самовывоз', icon: 'mdi-truck-delivery-outline' },
  //     { id: 2, title: 'Курьером', icon: 'mdi-map-marker-radius-outline' },
  //   ]
  //   commit('SET_DELIVERY', delivery)
  // },

  SET_USER: async ({ commit }, user) => {
    commit('SET_USER', user)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
