<template>
  <section id="contact" class="contact section-bg">
    <div class="container">
      <div class="section-title">
        <h2>Контакты</h2>
        <p>
          Было бы неплохо тут написать очень умную фигню о чем-то вечном и
          прекрасном
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-4">
          <div class="contact-about">
            <h3>Petale</h3>
            <p>
              ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ПИТАЛЕ»<br />ИНН/КПП
              5043044273/504301001<br />ОГРН 1115043006242 от 27 сентября 2011
              г.<br />
              Юридический адрес: МО, г.Серпухов, ул.Чехова, д.18, этаж 1. 142206
            </p>
            <div class="social-links">
              <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="inffo">
            <div class="d-flex align-items-center">
              <i class="bi bi-geo-alt"></i>
              <p>
                ул. 1-я Стекольная , д. 7, стр. 13, офис 9, г. Москва, 105404
              </p>
            </div>

            <div class="d-flex align-items-center mt-4">
              <i class="bi bi-envelope"></i>
              <p>info@petale.ru</p>
            </div>

            <div class="d-flex align-items-center mt-4">
              <i class="bi bi-phone"></i>
              <p>+7 (495) 729-86-27</p>
            </div>
          </div>
        </div>

        <div class="col-lg-5 col-md-8">
          <form
            action="forms/contact.php"
            method="post"
            role="form"
            class="php-email-form"
          >
            <div class="form-group">
              <input
                type="text"
                name="name"
                class="form-control"
                id="name"
                placeholder="Ваше имя"
                required
              />
            </div>
            <div class="form-group mt-3">
              <input
                type="email"
                class="form-control"
                name="email"
                id="email"
                placeholder="Ваша почта"
                required
              />
            </div>
            <div class="form-group mt-3">
              <input
                type="text"
                class="form-control"
                name="subject"
                id="subject"
                placeholder="Тема письма"
                required
              />
            </div>
            <div class="form-group mt-3">
              <textarea
                class="form-control"
                name="message"
                rows="5"
                placeholder="Письмо"
                required
              ></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">
                Your message has been sent. Thank you!
              </div>
            </div>
            <div class="text-center">
              <button type="submit">Отпарвить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactsComponent',
}
</script>

<style scoped></style>
