<template>
  <section id="delivery" class="delivery section-bg">
    <div class="container">
      <div class="section-title">
        <h2>Доставка</h2>
        <p>
          Для удобства наших клиентов мы осуществляем дотставку по Москве,
          Московской области а также в регионы. Условия доставки указаны ниже:
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <h3 class="delivery-title">Доставка курьером</h3>
          <div class="delivery-item pb-0">
            <h4>По Москве и МО</h4>
            <h5>за 1-2 дня</h5>
            <div>
              <ul>
                <li>При заказе от 5000 рублей — доставка бесплатная!</li>
                <li>Стоимость по Москве 300 руб</li>
                <li>Способ оплаты: наличными или пластиковой картой курьеру</li>
                <li>
                  Срок доставки по Москве: 1-2 дня с момента подтверждения
                  заказа
                </li>
                <li>
                  Москва за пределами МКАД и Подмосковье: условия уточняйте у
                  менеджера
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <h3 class="delivery-title">Доставка в регионы</h3>
          <div class="delivery-item">
            <h4>Осуществляется компанией СДЭК и почтой России</h4>
            <h5>от 3 до 30 дней</h5>
            <div>
              <ul>
                <li>Только по 100% предоплате</li>
                <li>
                  Стоимость зависит от тарифа службы доставки до вашего города
                </li>
                <li>
                  После отправки посылки мы высылаем Вам код отслеживания
                  посылки
                </li>
                <li>
                  Срок доставки: от 3 до 30 дней в зависимости от вашего
                  местонахождения
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <h3 class="delivery-title">Самовывоз</h3>
          <div class="delivery-item">
            <h4>ул. 1-я Стекольная, д. 7, стр. 13, офис 9, г. Москва</h4>
            <h5>бесплатно</h5>
            <div>
              <ul>
                <li>В удобное для Вас время</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <h3 class="delivery-title">Оплата</h3>
          <div class="delivery-item">
            <h4>Москва и ближайшее Подмосковье</h4>
            <div>
              <ul>
                <li>Наличными курьеру</li>
                <li>Картой Сбербанка</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DeliveryComponent',
}
</script>

<style scoped></style>
