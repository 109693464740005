<template>
  <footer id="footer">
    <div class="container">
      <div class="copyright">
        &copy; 2012-2023 <strong><span>Petale</span></strong>
      </div>
      <div class="credits">
        <a href="#" @click.prevent="showPrivacy">Политика конфиденциальности</a>
      </div>
    </div>

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>

    <v-dialog v-model="privacyWin" class="resp-width" :width="width">
      <PrivacyComponent @closeWindow="closeWindow" />
    </v-dialog>

    <v-snackbar v-model="cookieUse" timeout="-1" style="z-index: 9999">
      МЫ ИСПОЛЬЗУЕМ COOKIE. Продолжая использовать наш сайт, вы даете согласие
      на обработку файлов cookie. Если вы не хотите чтобы ваши данные
      обрабатывались, покиньте сайт.
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="cookieUse = false">
          Понятно
        </v-btn>
      </template>
    </v-snackbar>
  </footer>
</template>

<script>
import { select, onscroll } from '@/lib/functions'
import PrivacyComponent from '@/components/PrivacyComponent'
import { mapActions, mapGetters } from 'vuex'
import { copyObject } from '@/lib/objects'

export default {
  name: 'FooterComponent',
  data: () => ({
    privacyWin: false,
    cookieUse: true,
    user: null,
  }),
  watch: {
    async cookieUse(newVal) {
      this.user = copyObject(this.getUser)
      this.user.privacy = newVal
      await this.SET_USER(this.user)
    },
  },
  created() {
    this.user = copyObject(this.getUser)
    this.cookieUse = this.user.privacy
  },
  mounted() {
    let backtotop = select('.back-to-top')

    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }
  },
  components: {
    PrivacyComponent,
  },
  computed: {
    ...mapGetters(['getUser']),
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '100%'
        case 'sm':
          return '100%'
        case 'md':
          return '100%'
        case 'lg':
          return '70%'
        case 'xl':
          return '70%'
        default:
          return '100%'
      }
    },
  },
  methods: {
    ...mapActions(['SET_USER']),
    showPrivacy() {
      this.privacyWin = true
    },

    closeWindow() {
      this.privacyWin = false
    },
  },
}
</script>

<style scoped></style>
