<template>
  <section
    id="hero"
    class="d-flex flex-column align-items-center justify-content-center"
  >
    <h1>Petale</h1>
    <h2>Профессиональная косметика</h2>
    <a href="#about" class="btn-get-started scrollto"
      ><i class="bi bi-chevron-double-down"></i
    ></a>
  </section>
</template>

<script>
export default {
  name: 'HeroComponent',
}
</script>

<style scoped></style>
