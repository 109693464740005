import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/vendor/bootstrap/css/bootstrap.min.css'
import '@/assets/vendor/bootstrap-icons/bootstrap-icons.css'
import '@/assets/css/style.css'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import Vuelidate from 'vuelidate'
import Toast from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

const toastOptions = {
  timeout: 2500,
}

Vue.config.productionTip = false

Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(Toast, toastOptions)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
