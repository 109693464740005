import { render, staticRenderFns } from "./DeliveryComponent.vue?vue&type=template&id=475a884b&scoped=true&"
import script from "./DeliveryComponent.vue?vue&type=script&lang=js&"
export * from "./DeliveryComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475a884b",
  null
  
)

export default component.exports