<template>
  <v-row>
    <v-col class="text-left">
      <v-chip label text-color="white" color="pink">
        {{ cost ? item.UnitPrice * item.qt : item.UnitPrice }}
        ₽
      </v-chip>
    </v-col>
    <v-col v-if="cart">
      <v-btn x-small text color="red" @click="$emit('showRemoveDialog')">
        <v-icon left> mdi-close </v-icon>
        Удалить</v-btn
      >
    </v-col>
    <v-col class="text-right">
      <v-btn
        color="pink lighten-2"
        text
        @click="AddItemToBasket"
        v-if="BASKET_COUNT === 0"
      >
        В корзину
      </v-btn>
      <v-text-field
        :label="label ? 'В корзине' : ''"
        v-else
        :value="BASKET_COUNT"
        :min="1"
        :max="1000"
        oninput="if (Number(this.value) < Number(this.min)) this.value = this.min;if (Number(this.value) > Number(this.max)) this.value = this.max"
        @keypress="Filter"
        @keyup.prevent="setQtyToBasket"
        dense
        hide-details
        append-outer-icon="mdi-plus"
        prepend-icon="mdi-minus"
        @click:append-outer="AddItemToBasket"
        @click:prepend="subItemToBasket"
        class="centered-input"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'BasketActionsComponent',
  props: {
    cart: {
      type: Boolean,
      default: false,
    },
    cost: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getBasket']),
    /*BREAKPOINT() {
      return this.$vuetify.breakpoint.name
    },*/
    BASKET_COUNT() {
      return (
        this.getBasket.find(i => i.ProductID === this.item.ProductID)?.qt || 0
      )
    },
  },
  methods: {
    ...mapMutations(['ADD_BASKET', 'SUB_BASKET', 'SET_QTY_BASKET']),
    Filter(evt) {
      let expect = evt.key.toString()

      if (!/^[0-9]*$/.test(expect)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    setQtyToBasket(e) {
      const v = Number(e.target.value)
      this.SET_QTY_BASKET({ item: this.item, qt: v })
    },
    subItemToBasket() {
      this.SUB_BASKET(this.item)
    },
    AddItemToBasket() {
      this.ADD_BASKET(this.item)
    },
  },
}
</script>

<style>
.centered-input input {
  text-align: center;
}
</style>
