export default {
  APP_NAME: 'Petale - косметика для лица',

  defaultUser: {
    uid: null,
    token: null,
    privacy: true,
    basket: [],
    delivery: null,
    payment: null,
    roles: [],
    address: {
      street: '',
      entrance: '',
      floor: '',
      flat: '',
    },
    contacts: {
      ContactName: '',
      Phone: '',
      Email: '',
    },
    pVer: 1,
  },

  tb_token: '6266532271:AAFliv9qpf2S7o3KiY-gU9hXCMvfJqF-KJ0',

  settings: {
    apiKey: 'b30be05d-261e-4041-a221-51fdf65f0859',
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1',
  },
}
