<template>
  <div>
    <HeroComponent />
    <HeaderComponent />
    <div id="main">
      <AboutComponent />
      <ProductsComponent />
      <DeliveryComponent />
      <ContactsComponent />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import HeroComponent from '@/components/HeroComponent.vue'
import HeaderComponent from '@/components/HeaderComponent'
import AboutComponent from '@/components/AboutComponent'
import ContactsComponent from '@/components/ContactsComponent'
import DeliveryComponent from '@/components/DeliveryComponent'
import FooterComponent from '@/components/FooterComponent'
import ProductsComponent from '@/components/ProductsComponent'

export default {
  name: 'HomeView',
  components: {
    HeroComponent,
    HeaderComponent,
    AboutComponent,
    DeliveryComponent,
    ContactsComponent,
    FooterComponent,
    ProductsComponent,
  },
}
</script>
