import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/IndexView.vue'
import Strings from '@/store/const/Strings'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexView,
    meta: {
      auth: false,
      title: 'Профессиональная косметика для лица - купить в Москве',
    },
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () =>
      import(/* webpackChunkName: "Product" */ '@/views/ProductView'),
    meta: {
      auth: false,
      title: 'Название товара',
    },
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "Cart" */ '@/views/CartView'),
    meta: {
      auth: false,
      title: 'Корзина',
    },
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: () =>
      import(/* webpackChunkName: "Purchase" */ '@/views/PurchaseView'),
    meta: {
      auth: false,
      title: 'Оформление покупки',
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () =>
      import(/* webpackChunkName: "Payment" */ '@/views/PaymentView'),
    meta: {
      auth: false,
      title: 'Оплата',
    },
  },
  {
    path: '/order-info',
    name: 'OrderInfo',
    component: () => import('@/views/OrderInfo.vue'),
    meta: {
      auth: false,
      title: 'Заказ принят!',
    },
  },
  {
    path: '/page-not-found',
    alias: '*',
    /*beforeEnter: () => {
      console.log(666)
    },*/
    meta: { emptyLayout: true, title: 'Страница не найдена' },
    component: () =>
      import(/* webpackChunkName: "p404" */ '@/views/PageView404'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const docTitle = to => {
  return to.meta.title ?? Strings.APP_NAME
}

router.beforeEach((to, from, next) => {
  document.title = docTitle(to)
  next()
})

export default router
