<template>
  <header id="header" class="d-flex align-items-center">
    <div class="container d-flex align-items-center justify-content-between">
      <div class="logo">
        <a href="/"
          ><img src="@/assets/img/petale_logo.png" alt="" class="img-fluid"
        /></a>
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li>
            <a class="nav-link scrollto active" href="/#hero">Начало</a>
          </li>
          <li><a class="nav-link scrollto" href="/#about">О PETALE</a></li>
          <li>
            <a class="nav-link scrollto" href="/#portfolio"
              >Выбрать косметику</a
            >
          </li>
          <li>
            <a class="nav-link scrollto" href="/#delivery">Доставка и оплата</a>
          </li>
          <li><a class="nav-link scrollto" href="/#contact">Контакты</a></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
        <CartDisplayComponent />
      </nav>
    </div>
  </header>
</template>

<script>
import { select, onscroll, on, scrollto } from '@/lib/functions'
import CartDisplayComponent from '@/components/CartDisplayComponent'

export default {
  name: 'HeaderComponent',
  components: { CartDisplayComponent },
  mounted() {
    let selectHeader = select('#header')

    if (selectHeader) {
      let headerOffset = selectHeader.offsetTop
      let nextElement = selectHeader.nextElementSibling
      const headerFixed = () => {
        if (headerOffset - window.scrollY <= 0) {
          selectHeader.classList.add('fixed-top')
          nextElement.classList.add('scrolled-offset')
        } else {
          selectHeader.classList.remove('fixed-top')
          nextElement.classList.remove('scrolled-offset')
        }
      }
      window.addEventListener('load', headerFixed)
      onscroll(document, headerFixed)
    }

    let navbarlinks = select('#navbar .scrollto', true)

    const navbarlinksActive = () => {
      let position = window.scrollY + 200
      navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return
        let section = select(navbarlink.hash)
        if (!section) return
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add('active')
        } else {
          navbarlink.classList.remove('active')
        }
      })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)

    on('click', '.mobile-nav-toggle', function () {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })

    on(
      'click',
      '.navbar .dropdown > a',
      function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault()
          this.nextElementSibling.classList.toggle('dropdown-active')
        }
      },
      true
    )

    on(
      'click',
      '.scrollto',
      function (e) {
        if (select(this.hash)) {
          e.preventDefault()

          let navbar = select('#navbar')
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            let navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
          scrollto(this.hash)
        }
      },
      true
    )
  },
}
</script>

<style scoped></style>
