<template>
  <section id="portfolio" class="portfolio section-bg">
    <div class="container">
      <div class="section-title">
        <h2>Продукция</h2>
        <p>
          Petale - производитель косметики для профессионального ухода дома.
          Работающие омолаживающие комплексы.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-filters">
            <li
              v-for="(item, index) in Category"
              :key="item.CategoryID"
              :class="index === selected ? 'filter-active' : ''"
              @click.prevent="renderFilter(item.CategoryID)"
            >
              {{ item.CategoryName }}
            </li>
          </ul>
        </div>
      </div>

      <v-row class="portfolio-container">
        <v-col
          xs="12"
          sm="12"
          md="6"
          lg="4"
          v-for="product in Products"
          :key="product.ProductID"
          :class="`filter-${product.CategoryID}`"
        >
          <v-card class="card-outter" :height="height">
            <v-img
              :src="product?.Images[0] || '/images/petale_17474931684.jpg'"
              height="250px"
            ></v-img>
            <v-card-title>
              <a class="p-title" :href="`/product/${product.ProductID}`">
                {{ product.ProductName }}
              </a>
            </v-card-title>
            <v-card-text>{{ product.ShortName }}</v-card-text>
            <v-card-actions class="card-actions">
              <BasketActionsComponent :item="product" />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import Isotope from 'isotope-layout'
import { mapActions, mapGetters } from 'vuex'
import BasketActionsComponent from '@/components/BasketActionsComponent'

export default {
  name: 'ProductsComponent',
  components: {
    BasketActionsComponent,
  },
  data: () => ({
    grid: null,
    ready: false,
    selected: 0,
    showView: false,
    item: null,
  }),
  async mounted() {
    await this.GET_PRODUCTS()
  },
  computed: {
    ...mapGetters(['getProducts', 'getCategory']),
    Products() {
      return this.getProducts
    },
    Category() {
      const d = this.getCategory
      d.push({ CategoryID: 0, CategoryName: 'Все' })
      return d
    },
    height() {
      console.log(this.$vuetify.breakpoint.name)
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 415
        case 'sm':
          return 405
        case 'md':
          return 470
        case 'lg':
          return 415
        case 'xl':
          return 415
        default:
          return 415
      }
    },
  },
  methods: {
    ...mapActions(['GET_PRODUCTS']),
    renderFilter(filter) {
      if (!this.grid) this.grid = new Isotope('.portfolio-container', {})
      this.selected = filter
      const fl = filter > 0 ? `.filter-${filter}` : '*'
      this.grid.arrange({ filter: fl })
    },
  },
}
</script>

<style scoped>
.p-title {
  color: #63544a;
  font-size: 16px;
  font-weight: 600;
}
.p-title:hover {
  color: #d43076;
  text-decoration: underline;
}
.card-outter {
  padding-bottom: 50px !important;
  min-height: 435px !important;
}
.card-actions {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
  font-weight: 600;
  color: #000;
}
</style>
