<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { copyObject } from '@/lib/objects'
import { v4 as uuidv4 } from 'uuid'

export default {
  methods: {
    ...mapActions(['SET_USER']),
  },
  data: () => ({
    user: null,
  }),
  async created() {
    this.user = copyObject(this.getUser)
    if (!this.user.uid) {
      this.user.uid = uuidv4()
      await this.SET_USER(this.user)
    }
  },
  computed: {
    ...mapGetters(['getUser']),
  },
}
</script>

<style></style>
