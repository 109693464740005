<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row no-gutters">
        <div
          class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"
        ></div>
        <div class="col-xl-7 ps-lg-5 pe-lg-1 d-flex align-items-stretch">
          <div class="content d-flex flex-column justify-content-center">
            <h3>О производителе</h3>
            <p class="align-justify">
              PETALE — российская марка профессиональной косметики, появившаяся
              в результате многих научных исследований. Многолетний практический
              опыт создателей косметики PETALE, а также специалистов Кафедры
              технологии производства косметических и фармацевтических средств
              РХТУ им. Д. И. Менделеева позволили создать конкурентоспособные,
              эффективные продукты, отвечающие самым высоким требованиям.
            </p>
            <p class="align-justify">
              Безопасность и эффективность каждого продукта лежат в основе
              концепции PETALE. Использование современных технологий и
              разработок, а также качественных инновационных компонентов
              позволяет достичь эффекта за одну-две процедуры, а также
              гарантировать пролонгированное действие.
            </p>
            <p class="align-justify">
              Petale - производитель косметики для профессионального ухода дома.
              Работающие омолаживающие комплексы.
            </p>
          </div>
        </div>
      </div>

      <div class="row no-gutters mt-5">
        <div class="col-xl-7 pe-lg-5 d-flex align-items-stretch">
          <div class="content d-flex flex-column justify-content-center">
            <h3>Светлана Антипина</h3>
            <h6>Основательница и разработчик косметики Petale.</h6>
            <p class="align-justify">
              Почему именно я разрабатываю косметику? Все просто — у меня
              химическое образование (РХТУ им. Д. И. Менделеева) и опыт в
              разработках более 16 лет. Закончив институт, я стала работать по
              специальности на производстве профессиональной косметики. Не часто
              меняя работу, прошла путь от технолога до руководителя проекта по
              разработке бренда.
            </p>
            <p class="align-justify">
              В 2011 году я решила, что пора создать собственный продукт,
              который будет направлен только на косметологов. Я и моя команда
              разработали профессиональную косметику для салонов красоты.
            </p>
            <p class="align-justify">
              А через год я стала мамой и мое мировоззрение полностью
              изменилось. Научилась совмещать работу и материнство. А
              познакомившись с другими мамами, и исходя из собственного опыта,
              стало ясно, что поддерживать ухоженный вид современной женщине не
              так-то просто. Не всегда есть время, а иногда и материальная
              возможность посещать косметолога.
            </p>
            <p class="align-justify">
              На тот момент я стала думать, как же можно помочь таким женщинам,
              ведь косметика, которая продается в магазинах не дает полноценного
              ухода. С рождением второго ребенка укрепилась своей идеей и начала
              действовать.
            </p>
            <p class="align-justify">
              Полностью пересмотрев направленность — косметика для женщин,
              которые в силу тех или иных причин не могут посещать центры
              красоты. Совместно с косметологами были разработаны комплексные
              уходы по определенным проблемам с кожей. Благодаря комплексам
              женщины могут самостоятельно провести процедуру дома и получить
              эффект как после салона красоты.
            </p>
            <p class="align-justify">
              А в аккаунте <strong>@petale_professional</strong> мы
              рассказываем, как поддержать красоту и молодость, как правильно
              питаться, какая гимнастика для лица поможет скорректировать
              недостатки. И мы всегда открыты для новых идей и друзей!
            </p>
          </div>
        </div>
        <div
          class="image-s col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutComponent',
}
</script>

<style scoped>
.align-justify {
  margin-top: 10px;
  text-indent: 1.5em;
  text-align: justify;
}
</style>
