import Api from '@/libs/apiCall'

const state = {
  products: [],
  category: [],
  delivery: [],
  payment: [],
}

const getters = {
  getProducts: state => state.products,
  getCategory: state => state.category,
  getDelivery: state => state.delivery,
  getPayment: state => state.payment,
}

const mutations = {
  SET_PAYMENT: (state, list) => {
    state.payment = list
  },

  SET_DELIVERY: (state, list) => {
    state.delivery = list
  },

  SET_PRODUCTS: (state, list) => {
    const { products, category } = list
    state.products = products
    state.category = category
  },
}

const actions = {
  GET_PAYMENT: async ({ commit }) => {
    // TODO dict load from server
    const payment = [
      { id: 1, title: 'Наличными курьеру', icon: 'mdi-account-cash-outline' },
      {
        id: 2,
        title: 'Картой Сбербанка',
        icon: 'mdi-credit-card-outline',
      },
    ]
    commit('SET_PAYMENT', payment)
  },

  GET_DELIVERY: async ({ commit }) => {
    // TODO dict load from server
    const delivery = [
      { id: 1, title: 'Самовывоз', icon: 'mdi-truck-delivery-outline' },
      { id: 2, title: 'Курьером', icon: 'mdi-map-marker-radius-outline' },
    ]
    commit('SET_DELIVERY', delivery)
  },

  GET_PRODUCTS: async ({ commit }) => {
    // TODO dict load from server
    const { Products, Categories } = await Api.get('https://pb.akerio.ru/prod')
    commit('SET_PRODUCTS', { products: Products, category: Categories })
  },

  MAKE_ORDER: async (_, payload) => {
    const data = await Api.post(`/make-order`, payload)
    console.log(data)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
